import { useState } from "react";

const useWebchat = () => {
  const [chatShouldAppear, setChatShouldAppear] = useState(false);

  const displayChatBot = () => {
    if (!chatShouldAppear) {
      setChatShouldAppear(!chatShouldAppear);
    } else if (chatShouldAppear) {
      setChatShouldAppear(!chatShouldAppear);
    }
  };

  return [displayChatBot];
};

export default useWebchat;
