import AppRoutes from "AppRoutes";
// eslint-disable 

function App() {

    return (
        <div className=" w-full  bg-custom-bg-inspector  justify-between flex flex-col h-screen">
            <div className="grow">
                <AppRoutes />
            </div>
            <footer className="bg-custom-bg-inspector text-custom-text-gray pl-2 text-lg">
                � 2023 - TaskMate
                <a href="/privacy" className="text-custom-selected-tab underline">
                    Privacy
                </a>
            </footer>
        </div>
    );
}

export default App;
