import * as go from "gojs";
import { useState } from "react";

import { MAX_NODE_LENGTH, NODE_COLORS } from "utils/app.constants";
import { Inspector } from "./DataInspector.js";
import { LinkLabelDraggingTool } from "./linkLabelDragginTool/LinkLabelDraggingTool";

const useGraph = () => {
  const [ isInspector, setIsInspector ] = useState<boolean>(false);
  const { defaultNodeFillColor, defaultNodeStrokeColor, highlightedNodeFillColor, highlightedNodeStrokeColor } = NODE_COLORS;
  const selectedTab = sessionStorage.getItem("tab");
  const knowledgeSavedZoom = sessionStorage.getItem("savedZoomKnowledge");
  const skillsSavedZoom = sessionStorage.getItem("savedZoomSkills");
  const knowledgeSavedPosition = sessionStorage.getItem("savedPositionKnowledge");
  const skillsSavedPosition = sessionStorage.getItem("savedPositionSkills");

  const shouldUseAutoScale =  (knowledgeSavedZoom && selectedTab === '1') || (skillsSavedZoom && selectedTab === '2') ? false : true;
  const autoscaleOption = shouldUseAutoScale ? go.Diagram.Uniform : go.Diagram.None;
    
  function initDiagram() {
    const $ = go.GraphObject.make;
    // set your license key here before creating the diagram: go.Diagram.licenseKey = "...";
    const myDiagram = $(
      go.Diagram,
      { maxSelectionCount: 1 },
      (go.Diagram,
      "myDiagramsDiv", // must name or refer to the DIV HTML element
      {
        "undoManager.isEnabled": true,
        "clickCreatingTool.archetypeNodeData": {
          text: "new node",
          color: "rgb(127, 245, 227)",
        },
        model: $(go.GraphLinksModel, {
          nodeKeyProperty: "key",
          linkToPortIdProperty: "toPort",
          linkFromPortIdProperty: "fromPort",
          linkKeyProperty: "key", // IMPORTANT! must be defined for merges and data sync when using GraphLinksModel,
        }),

        
          initialAutoScale: autoscaleOption,
          contentAlignment: go.Spot.Center,

        //arranging nodes
        layout: $(
          go.LayeredDigraphLayout, // automatically spread nodes apart while dragging
          {
            layerSpacing: 200,
            aggressiveOption: go.LayeredDigraphLayout.AggressiveLess,
            columnSpacing: 28,
            cycleRemoveOption: go.LayeredDigraphLayout.CycleDepthFirst,
            direction: 0,
            initializeOption: go.LayeredDigraphLayout.InitDepthFirstOut,
            iterations: 5,
          }
        ),
        "linkingTool.archetypeLabelNodeData": {
          category: "LinkLabel",
        },
        SelectionMoved: (e: any) => e.diagram.layout.invalidateLayout(),
      })
    );

    myDiagram.toolManager.mouseMoveTools.insertAt(
      0,
      new LinkLabelDraggingTool()
    );

    //zoom inside graph with mouse wheel
    myDiagram.toolManager.mouseWheelBehavior = go.ToolManager.WheelZoom;
    myDiagram.scrollMode = go.Diagram.InfiniteScroll;
    if(myDiagram instanceof go.Diagram) {
        myDiagram.addDiagramListener('ChangedSelection', (e) => {
            const inspector = document.getElementById('myInspectorDiv');
            setIsInspector(true);       
                                            
            if(inspector) {
                inspector.classList.add('visible');
            }
            
            setTimeout(() => {
                if(inspector) {
                   const inputToSet =  inspector.querySelector('input');
                   if(inputToSet) {
                    inputToSet.setAttribute('maxLength', MAX_NODE_LENGTH.toString())
                   }
                }
            }, 200)
        });
    }

        myDiagram.nodeTemplate = $(
          go.Node,
          "Auto",
          {
            click: function(e, node) {
              e.diagram.commandHandler.resetZoom();
              e.diagram.scrollToRect(node.actualBounds);
              e.diagram.contentAlignment = go.Spot.Center;
          },
          },
          new go.Binding("location", "loc", go.Point.parse).makeTwoWay(
            go.Point.stringify
          ),
          $(
            go.Shape,
            "RoundedRectangle",
            {
              fill: defaultNodeFillColor,
              portId: "",
              fromLinkable: true,
              fromSpot: go.Spot.AllSides,
              toLinkable: true,
              toSpot: go.Spot.AllSides,
              cursor: "pointer",              
            },
            new go.Binding("fill", "color"),
            new go.Binding("stroke", "highlighted", function(highlighted) { return highlighted ? highlightedNodeStrokeColor : defaultNodeStrokeColor}),
            new go.Binding("fill", "highlighted", function(highlighted) { return highlighted ? highlightedNodeFillColor : defaultNodeFillColor}),
            new go.Binding("strokeWidth", "strokeWidth",
                function(strokeWidth) { 
                    return strokeWidth ? strokeWidth : 1
                }
            )
            // new go.Binding("fill", "isFound", function (isFound) {
            //   return isFound === true ? "orange" : "rgb(127, 133, 245)";
            // })
          ),
          $(
            go.TextBlock,
            {
              margin: 8,
              editable: true,
              overflow: go.TextBlock.OverflowEllipsis,
              textValidation: function (
                textblock: any,
                oldst: any,
                newstr: any
              ) {
                return newstr.length >= 1;
              },
            },
            new go.Binding("text").makeTwoWay()
          ),
          {
            toolTip: $(
              "ToolTip",
              $(go.TextBlock, { margin: 4 }, new go.Binding("text", "text"))
            ),
          }
        );

        //Set limit to 100 characters for Node label
        var ed = myDiagram.toolManager.textEditingTool.defaultTextEditor;
        if (ed instanceof go.HTMLInfo && ed.mainElement instanceof HTMLTextAreaElement) {
            ed.mainElement.maxLength = MAX_NODE_LENGTH;
        }

        // replace the default Link template in the linkTemplateMap
        myDiagram.linkTemplate = $(
          go.Link, // the whole link panel
          { relinkableFrom: true, relinkableTo: true, selectionAdorned: false },
          $(go.Shape, {
            isPanelMain: true,
            stroke: "transparent",
            strokeWidth: 8,
          }),
          $(
            go.Shape, // the link shape
            { isPanelMain: true },
            new go.Binding("stroke", "key", function (key) {
              return typeof key === "number" ? "orange" : "rgb(173, 173, 173)";
            }).ofObject(),
            new go.Binding("strokeWidth", "isSelected", function (s) {
              return s ? 4 : 1;
            }).ofObject()
          ),
          $(
            go.Shape, // the arrowhead
            {
              toArrow: "Triangle",
              strokeWidth: 0,
              fill: "rgb(173, 173, 173)",
            }
          ),
          $(
            go.Panel,
            "Auto",
            $(
              go.Shape, // the label background, which becomes transparent around the edges
              {
                fill: $(go.Brush, "Radial", {
                  0: "rgb(41, 41, 41)",
                  0.3: "rgb(41, 41, 41)",
                  1: "rgba(41, 41, 41, 0)",
                }),
                stroke: null,
              }
            ),
            $(
              go.TextBlock, // the label text
              {
                editable: true,
                textAlign: "center",
                font: "10pt arial, sans-serif",
                stroke: "rgb(173, 173, 173)",
                margin: 4,
                textValidation: function (
                  textblock: any,
                  oldst: any,
                  newstr: any
                ) {
                  return newstr.length >= 1;
                },
              },
              "new link",
              new go.Binding("text", "text").makeTwoWay()
            ),
            new go.Binding("segmentFraction").makeTwoWay()
          ),
          {
            // a mouse-over highlights the link by changing the first main path shape's stroke:
            mouseEnter: function (e, link: any) {
              link.elt(0).stroke = "rgba(127, 133, 245, 0.3)";
            },
            mouseLeave: function (e, link: any) {
              link.elt(0).stroke = "transparent";
            },
          }
        );

        new Inspector("myInspectorDiv", myDiagram, {
            showAllProperties: true,
            properties: {
                text: {},
                name: { readOnly: true, show: Inspector.showIfPresent },
                key: { readOnly: true, show: Inspector.showIfPresent },
                color: { show: false, type: "color" },
            },
        }); 
            

        myDiagram.addModelChangedListener(function () {
            //sizing Nodes
            myDiagram.nodes.each(function (node) {
                let linkIterator = node.findLinksConnected();
                let numberOfLinks = 0;
                while (linkIterator.next()) {
                    numberOfLinks++;
                }
                node.desiredSize = new go.Size(
                    numberOfLinks * 10 + 100,
                    numberOfLinks * 5 + 50
                );
            });
        });

        //add animation when graph is loading
        myDiagram.animationManager.initialAnimationStyle =
            go.AnimationManager.AnimateLocations;
        myDiagram.model = go.Model.fromJson(myDiagram.model.toJson());
        
  
        
        //get the diagram scale value and position after zoom
        myDiagram.addDiagramListener("ViewportBoundsChanged", () => {     
          const position =  myDiagram.position.copy();
          const diagramPosition = { "x": position.x.toString(), "y": position.y.toString() }

          if(selectedTab === '1') {
            sessionStorage.setItem("zoomValueKnowledge", myDiagram.scale.toString());
            sessionStorage.setItem("positionValueKnowledge", JSON.stringify(diagramPosition));
          }  

          if(selectedTab === '2') {
            sessionStorage.setItem("zoomValueSkills", myDiagram.scale.toString());
            sessionStorage.setItem("positionValueSkills", JSON.stringify(diagramPosition));  
          }
        });

        const prepareGraphScale = (tabSavedPosition: string, tabSavedZoom: string) => {
          const graphPosition = JSON.parse(tabSavedPosition)
          myDiagram.scale = Number(tabSavedZoom);
          myDiagram.initialPosition = new go.Point(Number(graphPosition.x), Number(graphPosition.y));
        }

        const setGraphScale = () => {        
          if(knowledgeSavedZoom && knowledgeSavedPosition && selectedTab === '1') {
            prepareGraphScale(knowledgeSavedPosition, knowledgeSavedZoom);
          }

          if(skillsSavedZoom && skillsSavedPosition && selectedTab === '2') {         
            prepareGraphScale(skillsSavedPosition, skillsSavedZoom);   
          }      
      };
       
        myDiagram.addDiagramListener("InitialLayoutCompleted", setGraphScale);
        
        return myDiagram;
      }

    return { initDiagram, isInspector };
};

export default useGraph;

