import { ITab } from "hooks/interfaces/interfaces";

export const DIRECT_LINE_TOKEN_URL = "https://directline.botframework.com/v3/directline/tokens/generate";
export const SPEECH_SERVICES_TOKEN_URL = "https://westeurope.api.cognitive.microsoft.com/sts/v1.0/issueToken";
export const MAX_NODE_LENGTH = 100;
export const IDEMPOTENT_POST_PROCESSINGS = ["reloadGraph"];
export const USER_ID = "84e3e0d4-ec31-4189-a270-46fa08b2f185";
export const TASKMATE_DEFAULT_NAME = "TaskMate Assistant";

export const tabs: ITab[] = [
    { id: 1, label: "Knowledge" },
    { id: 2, label: "Skills" },
    { id: 3, label: "AuditLog" },
    { id: 4, label: "Commands" }
];

export const DEFAULT_NODE_FIELDS = ["text", "key", "properties", "highlighted", "strokeWidth", "loc", "color"];

export const NODE_COLORS = {
    defaultNodeFillColor: "rgb(127, 133, 245)",
    defaultNodeStrokeColor: "rgb(0, 0, 0)",
    highlightedNodeFillColor: "rgb(130, 207, 161)",
    highlightedNodeStrokeColor: "rgb(255, 255, 255)",
};
