import { useState, useEffect, useContext, Suspense } from "react";
import { app } from "@microsoft/teams-js";

import Webchat from "components/WebChat/Webchat";
import Tabs from "components/Tabs";
import LogComp from "components/Logs/LogsTable";
import configuration from "configuration";
import CommandsList from "components/CommandsList/CommandsList";
import TeamsContext from "context/TeamsContext";
import Spinner from "components/Spinner/Spinner";
import { Graph } from "components/Graph/Graph";
import { ErrorToaster } from "components/ErrorToaster/ErrorToaster";
import { tabs } from "utils/app.constants";
import { useGraphActions } from "hooks/useGraphActions";
import { IAllNodesAndLinks } from "hooks/interfaces/interfaces";
import { createDirectLineFunction } from "hooks/callDirectLine";

import "../../styles/global.css";

export const ViewerPage = () => {
  const initialGraphData = { nodes: [], links: [] };
  const [directLine, setDirectLine] = useState<any>();
  const [ponyfill, setPonyfill] = useState<any>();
  const [loadMessages, setLoadMessages] = useState<number>(0);
  const [toastError, setToastError] = useState<string>("");
  const [reloads, setReloads] = useState<number>(1);
  const [status, setStatus] = useState<any>();
  const [selectedTabId, setSelectedTabId] = useState(1);
  const [restartDirectLine, setRestartDirectLine] = useState(false);
  const [updateLogMessages, setUpdateLogMessages] = useState<number>(0);
  const [allNodesAndLinks, setAllNodesAndLinks] = useState<IAllNodesAndLinks>(initialGraphData);
  const [currentUser, setCurrentUser] = useState<{ [key: string]: string }>({});
  const context: any = useContext(TeamsContext);
  const knowledgeZoom = sessionStorage.getItem("zoomValueKnowledge");
  const skillsZoom = sessionStorage.getItem("zoomValueSkills");
  const knowledgePosition = sessionStorage.getItem("positionValueKnowledge");
  const skillsPosition = sessionStorage.getItem("positionValueSkills");

  const {
    handlerSendMessageToAuditLog,
    handlerDirectLineResponse,
    loadGraph,
    handleAddNode,
    handleAddLink,
    handleUpdateLink,
    handleDeleteLink,
    handleDeleteNode,
    handleUpdateNode,
    getGraphType,
    handleGetNodeChildren
  } = useGraphActions({
    setToastError,
    setAllNodesAndLinks,
    setRestartDirectLine,
    directLine,
    selectedTabId,
    currentUser,
    allNodesAndLinks,
  });

  useEffect(() => {
    app.getContext().then((context: any) => {
      const fullName = context?.user?.userPrincipalName
        ?.split("@")[0]
        .replace(".", " ");
      setCurrentUser({
        email: context?.user?.loginHint?.toLowerCase(),
        fullName: fullName,
        tid: context?.user?.tenant?.id,
        userid: context?.user?.id,
        firstName: fullName?.split(" ")[0],
        initials:
          fullName
            ?.split(" ")
            .map((n: any) => n[0].toUpperCase())
            .join("") || "ME",
      });
    });
  }, [restartDirectLine]);
  useEffect(() => {
    if (!!currentUser?.fullName) {
      localStorage.setItem("userId", currentUser?.userid);
      localStorage.setItem("userEmail", currentUser?.email);
      localStorage.setItem("fullName", currentUser?.fullName);
      let cachedGraph = localStorage.getItem(sessionStorage.getItem("tab") as string);
      if (cachedGraph) {
        handlerDirectLineResponse(JSON.parse(cachedGraph));
      }

      let authToken;
      if (context.authCode) {
        authToken = context.authCode;
        localStorage.setItem("authToken", context.authCode);
      } else {
        authToken = localStorage.getItem("authToken");
      }
      const conversationDataCallUrl = `${configuration.chatApiURL}/${
        currentUser?.email?.length
          ? currentUser.email
          : configuration.chatUserEmail
      }`;
      createDirectLineFunction(
        conversationDataCallUrl,
        authToken,
        setDirectLine,
        setPonyfill
      );
      const message = "Starting conversation with user.";
      handlerSendMessageToAuditLog(message);
    }
  }, [ currentUser, restartDirectLine ]);

  useEffect(() => {
    directLine?.connectionStatus$.subscribe((status: number) => {
      if (status === 5) {
        setToastError(
          "Oops, something went wrong with your assistant. Error details: Unable to connect."
        );
      }
      setStatus(status);
      const message = "Unable to connect with DirectLine(status 5).";
      handlerSendMessageToAuditLog(message);
    });

    return () => {
      directLine?.connectionStatus$.unsubscribe();
    };
  }, [ directLine, restartDirectLine ]);

  useEffect(() => {
    if (status === 2 && directLine) {
      directLine.activity$.subscribe((res: any) => {
        handlerDirectLineResponse(res);
      });

      if (selectedTabId !== 3 && selectedTabId !== 4) {
        loadGraph(getGraphType(selectedTabId));
      }
      return;
    }

    if (status === 4) {
      directLine?.activity$.subscribe(
        () => { },
        (error: any) =>
          setToastError(
            `Oops, something went wrong with your assistant. Error details: ${error.message}`
          )
      );
    }
  }, [status]);



  useEffect(() => {
    sessionStorage.setItem("tab", JSON.stringify(selectedTabId));
    setAllNodesAndLinks(initialGraphData)

    setAllNodesAndLinks({
      nodes: [],
      links: []
    })
    if (selectedTabId !== 3 && selectedTabId !== 4) {
      loadGraph(getGraphType(selectedTabId));
    }

    knowledgeZoom && sessionStorage.setItem("savedZoomKnowledge", knowledgeZoom?.toString());
    knowledgePosition && sessionStorage.setItem("savedPositionKnowledge", knowledgePosition);
    skillsZoom && sessionStorage.setItem("savedZoomSkills", skillsZoom?.toString());
    skillsPosition && sessionStorage.setItem("savedPositionSkills", skillsPosition);    
  }, [selectedTabId]);

  const handlerLoadMoreMessage = () => {
    setReloads(reloads + 1);
    setLoadMessages(0);
  };

  return (
    <div className="p-0 m-0">
      <Tabs tabs={tabs} selected={selectedTabId} onClick={setSelectedTabId} />
      <div className="w-full h-full">
          <Suspense>
            <div className={`w-3/4 ${selectedTabId === 3 ? "visible" : "hidden"}`}>
              <LogComp
                updateLogMessages={updateLogMessages}
                reloads={reloads}
                onLoadMessages={setLoadMessages}
              />
              <div className={`justify-center items-center my-auto mt-10 ${loadMessages >= 1 && loadMessages === reloads * 100 ? "visible_flex" : "hidden"}`}>
                <button
                  onClick={handlerLoadMoreMessage}
                  className="px-4 py-2 font-semibold bg-transparent border rounded hover:bg-custom-selected-tab text-custom-selected-tab hover:text-white border-custom-selected-tab hover:border-transparent"
                >
                  Load more
                </button>
              </div>
            </div>
          </Suspense>

          {selectedTabId === 3 || selectedTabId === 4 ? null : (
            <div className="w-full h-full">
              <ErrorToaster
                error={toastError}
                setError={(error: any) => setToastError(error)}
              />
              {Array.isArray(allNodesAndLinks.links) &&
                allNodesAndLinks.nodes.length > 0 &&
                !!directLine ? (
                <Graph
                  allNodes={allNodesAndLinks.nodes}
                  allLinks={allNodesAndLinks.links}
                  handleAddNode={handleAddNode}
                  handleAddLink={handleAddLink}
                  handleUpdateLink={handleUpdateLink}
                  handleDeleteLink={handleDeleteLink}
                  handleDeleteNode={handleDeleteNode}
                  handleUpdateNode={handleUpdateNode}
                  handleGetNodeChildren={handleGetNodeChildren}
                />
              ) : (
                <Spinner />
              )}
            </div>
          )}
      </div>
      <div className={`${selectedTabId !== 4 && !!directLine ? "visible" : "hidden"}`}>
        <Webchat
          directLine={directLine}
          ponyfill={ponyfill}
          onUpdateLogMessages={setUpdateLogMessages}
          onLoadMessages={setLoadMessages}
        />
      </div>
      {selectedTabId === 4 && (
        <Suspense>
          <div>
            <CommandsList />
          </div>
        </Suspense>
      )}
    </div>
  );
};
