import { Dispatch, SetStateAction } from "react";

import { USER_ID } from "utils/app.constants";
import { getDateTime } from "utils/getDateTime";
import { requestBuilderTs } from "utils/requestBuilder";
import { ILogResponse, Messages } from "./interfaces/interfaces";

export const fetchAndOrderLogs = (
  corId: string | null,
  reloads: number,
  setMessages: Dispatch<SetStateAction<Messages[]>>,
  setAddSpinner: Dispatch<SetStateAction<boolean>>
) => {
  setAddSpinner(true);
  requestBuilderTs(
    `${window.location.href}api/logs?userId=${
      corId !== "undefined" ? corId : USER_ID
    }&reloads=${reloads}`,
    "GET",
    {}
  ).then((res) => {
    const sortedRes = res.sort(function (x: ILogResponse, y: ILogResponse) {
      return Date.parse(y.timestamp) - Date.parse(x.timestamp);
    });
    const messagesArray = (sortedRes || []).map((item: ILogResponse) => {
      const { timestamp, message, application } = item;
      const { date, month, year, hours, minutes, seconds } =
        getDateTime(timestamp);
      const datestring = `${date}-${month}-${year} ${hours}:${minutes}:${seconds}`;

      return {
        time: datestring,
        message: message,
        application: application,
      };
    });
    setAddSpinner(false);
    setMessages(messagesArray);
  });
};
