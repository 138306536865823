import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import CommandsListPage from "pages/CommandsListPage/CommandsListPage";
import { ViewerPage } from "pages/ViewerPage/ViewerPage";

export interface IApplicationProps {}

const AppRoutes: React.FunctionComponent<IApplicationProps> = (props) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ViewerPage/>} />
        <Route path="/commands-list" element={<CommandsListPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
