import React, { useEffect, useState } from "react";

import Spinner from "components/Spinner/Spinner";
import { ILogsTable, Messages } from "hooks/interfaces/interfaces";
import { fetchAndOrderLogs } from "hooks/fetchAndOrderLogs";
import { USER_ID } from "utils/app.constants";

import "./LogsTable.css";

const LogComp = ({
  updateLogMessages,
  reloads,
  onLoadMessages,
}: ILogsTable) => {
  const [messages, setMessages] = useState<Messages[]>([]);
  const [addSpinner, setAddSpinner] = useState<boolean>(false);

  useEffect(() => {
    if (onLoadMessages) {onLoadMessages(messages.length)};
  }, [messages]);

  useEffect(() => {
    const correlationIdFromLocStor = localStorage.getItem("userId");
    const correlationID =
      correlationIdFromLocStor !== "undefined"
        ? correlationIdFromLocStor
        : USER_ID;
    fetchAndOrderLogs(correlationID, reloads, setMessages, setAddSpinner);
  }, [updateLogMessages, reloads]);

  return (
    <>
      {messages.length && !addSpinner ? (
        messages.map((messagee, index) => {
          const { application, message, time } = messagee;

          return (
            <div key={index} className="container">
              <div className="time-left">{application}</div>
              <div className="message">{message}</div>
              <span className="time-left">{time}</span>
            </div>
          );
        })
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default React.memo(LogComp);
