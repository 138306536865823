export const getDateTime = (timestamp: any) => {
  const d = new Date(Date.parse(timestamp));
  const date = d.getDate();
  const month = d.getMonth() + 1;
  const year = d.getFullYear();
  const hours = d.getHours();
  const minutes = d.getMinutes();
  const seconds = d.getSeconds();

  return { date, month, year, hours, minutes, seconds };
};
