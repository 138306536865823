import React, { useEffect, useState } from "react";

import configuration from "configuration";
import Spinner from "components/Spinner/Spinner";

import "./CommandsList.css";

const CommandsList = () => {
  const [expandedItems, setExpandedItems] = useState<number[]>([]);
  const [commandsList, setCommandsList] = useState<any>();

  useEffect(() => {
    fetch(configuration.kbTemplatesUrl)
      .then((res) => res.json())
      .then((result) => {
        setCommandsList(result.templates);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleItemClick = (id: any) => {
    if (expandedItems.includes(id)) {
      let expandedItemsCopy = [...expandedItems];

      expandedItemsCopy = expandedItemsCopy.filter((item) => item !== id);
      setExpandedItems(expandedItemsCopy);
    } else {
      setExpandedItems([...expandedItems, id]);
    }
  };

  if (commandsList)
    commandsList.forEach((item: any, i: number) => {
      item.id = i + 1;
    }); 

  return (
    <div className="commands-list">
      {commandsList ? (
        commandsList.map((command:any) => (
          <div key={command.id}>
            <div
              className="commands-list__command"
              onClick={() => handleItemClick(command.id)}
            >
              {command.name}
            </div>
            {
              <div>
                {command.children.map((child:any) => (
                  <div
                    key={child.name}
                    className={
                      !expandedItems.includes(command.id)
                        ? "commands-list_children commands-list_children--closed"
                        : "commands-list_children commands-list_children--opened"
                    }
                  >
                    <div
                      className="commands-list_children-name"
                    >
                      {child.name}
                    </div>
                    <div
                      className="commands-list_children-description"
                    >
                      {child.description}
                    </div>
                  </div>
                ))}
              </div>
            }
          </div>
        ))
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default React.memo(CommandsList);
