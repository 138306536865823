import React, { useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { createStyleSet, createStore } from "botframework-webchat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCommentDots,
  faClose,
  faWindowRestore,
  faSquare,
} from "@fortawesome/free-solid-svg-icons";

import { USER_ID, TASKMATE_DEFAULT_NAME } from "utils/app.constants";
import useWebchat from "hooks/useWebchat";

import "./Webchat.css";

var markdown = require("markdown-it")({ html: true, linkify: true });
const WebChatV2 = window["WebChat" as keyof typeof window];
const styleSet = createStyleSet({
  bubbleTextColor: "#7f7f7f",
  bubbleBackground: "#292929",
  bubbleBorderRadius: 10,
  bubbleBorderWidth: 0,
  bubbleFromUserBorderRadius: 10,
  bubbleFromUserBorderWidth: 0,
  bubbleFromUserBackground: "#7b83eb",
  bubbleFromUserTextColor: "#000000",
  bubbleImageHeight: 38,
  bubbleMinHeight: 38,
  avatarSize: 38,
  monospaceFont: "'Courier New', monospace",
  primaryFont: "'Roboto', sans-serif",
  hideUploadButton: true,
  sendBoxBackground: "#292929",
  sendBoxBorderTop: "1px solid #000000",
  sendBoxHeight: 30,
  showSpokenText: true,
  botAvatarBackgroundColor: "#292929",
  userAvatarBackgroundColor: "#7b83eb",
  fontSizeSmall: 12,
});
styleSet.textContent = Object.assign({}, styleSet.textContent, {
  fontWeight: "500",
  fontSize: "12px",
});

const avatarOptions = {
  sendBoxTextWrap: true,
  hideUploadButton: true,
  botAvatarInitials: "TM",
  userAvatarInitials: "Me",
  height: "20px",
  botAvatarImage:
    "https://docs.microsoft.com/en-us/azure/bot-service/v4sdk/media/logo_bot.svg?view=azure-bot-service-4.0",
};

const Webchat = ({
  directLine,
  ponyfill,
  email,
  onUpdateLogMessages,
  onLoadMessages,
}: any) => {
  const [showChat, setShowChat] = useState(false);
  const [isLargeChat, setIsLargeChat] = useState<boolean>(false);
  const displayChatBot = useWebchat()[0];

  const chatWidth = isLargeChat ? "w-[85%]" : "w-[325px]";
  const chatHeight = isLargeChat ? "h-[85%]" : "h-[50%]";

  const store = useMemo(
    () =>
      createStore({}, ({ dispatch }: any) => (next: any) => (action: any) => {
        if (action.type === "DIRECT_LINE/POST_ACTIVITY") {
          const graphName = {
            context: {
              graph_name:
                (sessionStorage.getItem("tab") as string) !== null &&
                  (sessionStorage.getItem("tab") as string) === "2"
                  ? "skills"
                  : "knowledge",
            },
            userId:
              localStorage.getItem("userId") !== "undefined"
                ? localStorage.getItem("userId")
                : USER_ID,
            correlationId: uuidv4(),
            from: localStorage.getItem("userEmail"),
            apptype: "trainer",
            fullName: localStorage.getItem("fullName") !== "undefined"
              ? localStorage.getItem("fullName")
              : TASKMATE_DEFAULT_NAME
          };
          action.payload.activity.channelData = graphName;
        }

        const activityExists = !!action.payload?.activity;
        const addActivityText = !!activityExists
          ? action.payload.activity?.text
          : false;
        const indexOfSpace = addActivityText
          ? addActivityText.indexOf(" ")
          : false;

        const splittedActivity = addActivityText
          ? addActivityText.substring(indexOfSpace)
          : false;

        if (
          !!addActivityText &&
          addActivityText.includes("add") &&
          !!splittedActivity &&
          splittedActivity.length > 1001
        ) {
          return;
        }

        var expression =
          /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
        var regex = new RegExp(expression);

        if (
          !!addActivityText &&
          addActivityText.match(regex) &&
          action.type === "DIRECT_LINE/INCOMING_ACTIVITY"
        ) {
          const urlQP = addActivityText.split("sysparm_article=")[1];
          if (!urlQP) {
            action.payload.activity.text = `<a target="_blank" style="color: blue; cursor: pointer; font-style: italic; font-size: 16px;" href="${addActivityText}">Link</a>`;
            action.payload.activity.textFormat = "html";
          }
          else{
            action.payload.activity.text = addActivityText;
          }
        }

        if (action.type == "DIRECT_LINE/POST_ACTIVITY_FULFILLED") {
          let num = Math.random();
          onUpdateLogMessages(num);
          onLoadMessages(0);
        }
        return next(action);
      }),
    []
  );

  return (
    <div>
      <div
        className={
          showChat
            ? `fixed z-50 bottom-[50px] right-[50px] rounded ${chatWidth} ${chatHeight} min-h-[270px] p-[10px] border  webchat__main`
            : "hidden"
        }
      >
        <header className="pb-[6] text-right webchat__header">
          <span
            className="webchat__icon"
            onClick={() => setIsLargeChat(!isLargeChat)}
            title="Expand Chat window"
          >
            <FontAwesomeIcon icon={isLargeChat ? faWindowRestore : faSquare} />
          </span>
          <span
            onClick={() => {
              displayChatBot();
              setShowChat(false);
            }}
          >
            <FontAwesomeIcon icon={faClose} className="webchat__icon" />
          </span>
        </header>

        <div
          id="webchat"
          role="main"
          className="w-full min-w-[100px] h-[90%] min-h-[200px] webchat__main"
        >
          {directLine && (
            <WebChatV2.ReactWebChat
              directLine={directLine}
              store={store}
              styleOptions={avatarOptions}
              styleSet={styleSet}
              username={email}
              renderMarkdown={markdown.render.bind(markdown)}
              webSpeechPonyfillFactory={() => ponyfill}
            />
          )}
        </div>
      </div>

      <div
        className="webchat__icon-wrapper"
        onClick={() => {
          displayChatBot();
          setShowChat(!showChat);
        }}
      >
        <FontAwesomeIcon icon={faCommentDots} className="webchat__icon" />
      </div>
    </div>
  );
};

export default React.memo(Webchat);
