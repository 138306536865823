import { ObjectData } from "gojs";

import { IAllNodes, INodePropertiesObject } from "hooks/interfaces/interfaces";
import { DEFAULT_NODE_FIELDS } from "utils/app.constants";

export const checkNodeUpates = (oldNode: ObjectData, newNode: ObjectData) => {
    const nodeKeys: string[] = Object.keys(oldNode);
    const newNodeKeys: string[] = Object.keys(newNode);
    let areEqual: boolean = true;

    nodeKeys.forEach((key: keyof any) => {
      if (!newNode) {
        return;
      };

      const modifiedNode = newNode;

      if (oldNode[key as keyof IAllNodes] !== modifiedNode[key as keyof go.ObjectData] || nodeKeys.length < newNodeKeys.length) {
          areEqual = false;
      };
    });

    return areEqual;
};

export const getUpdatedNodeProperties = (oldNode: ObjectData, newNode: ObjectData, exceptionProp: string = 'loc' ) => {
    const nodeKeys: string[] = Object.keys(oldNode);
    const newNodeKeys: string[] = Object.keys(newNode);
    const addedKeys: string[] = newNodeKeys.filter(key => !nodeKeys.includes(key));        
    const nonDefaultProperties: ObjectData = {...newNode};
    const addedProperties: INodePropertiesObject = {};

    //sorts out the default node properties that are not updated trough this function
    DEFAULT_NODE_FIELDS.forEach((field: string) => delete nonDefaultProperties[field]);    

    addedKeys.forEach(key => {
        if(key !== exceptionProp){
            (addedProperties[key] = newNode[key])
        }
    });

    const updatedProperties = {...addedProperties, ...nonDefaultProperties};

    return updatedProperties;
};

export const parseNodeProperties = (stringProperties: string) => {
    const newProperties = JSON.parse(stringProperties);
    //sort out the default properties from stringified properties
    const {name_uc, partitionKey, ...nonDefaultProperties} = newProperties;

    return nonDefaultProperties;
};