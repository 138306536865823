function DeleteNodeDialog({ node, onClose, onDelete }: any) {
  if (!node) return null;
  return (
    <div className="absolute flex justify-center w-screen h-screen z-50 bg-white/10">
      <div className="relative p-5 w-auto h-32 mt-32 bg-custom-tabs text-custom-text-gray rounded-md">
        Confirm delete node '{node.text}'
        <div className="relative flex justify-around p-6">
          <button
            className="p-1 bg-custom-selected-tab rounded-md text-white hover:bg-custom-text-gray"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="p-2 bg-custom-selected-tab rounded-md text-white hover:bg-custom-text-gray"
            onClick={() => {
              onDelete();
              onClose();
            }}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeleteNodeDialog;
