import { parseNodeProperties } from "service/UpdateNodeHelpers";

//const nodesMapping = (nodes: IAllNodes[]) => {
const nodesMapping = (nodes: any) => {
  return nodes?.map((a: any) => {
    a["text"] = a["Name"];
    delete a["Name"];
    a["key"] = a["Id"];
    delete a["Id"];
    a["properties"] = a["Properties"];
    delete a["Properties"];
    if (a.properties) {
      a.properties = JSON.stringify(a.properties);
    }
    a.color = "rgb(127,133,245)";
    a.highlighted = false;
    a.strokeWidth = 1;    
    
    const nonDefaultProps = parseNodeProperties(a.properties);

    return {...a, ...nonDefaultProps};
  });
};

const rootsMapping = (nodes: any, findText: string) => {
  return nodes?.map((a: any) => {
    a["text"] = a["Name"];
    delete a["Name"];
    a["key"] = a["Id"];
    delete a["Id"];
    a["properties"] = a["Properties"];
    delete a["Properties"];
    if (a.properties) {
      a.properties = JSON.stringify(a.properties);
    }
    a.color = "rgb(127,133,245)";
    a.highlighted = true;
    a.strokeWidth = a.text.toLowerCase() === findText.toLowerCase() ? 5 : 3;

    return a;
  });
};

//const linksMapping = (links: IAllLinks[]) => {
const linksMapping = (links: any) => {
  return links?.map((a: any) => {
    a["from"] = a["Source_node_id"];
    delete a["Source_node_id"];
    a["to"] = a["Target_node_id"];
    delete a["Target_node_id"];
    a["key"] = a["Id"];
    delete a["Id"];
    a["text"] = a["Name"];
    delete a["Name"];

    return a;
  });
};


//const nodesMapping = (nodes: IAllNodes[]) => {
  const nodesMappingDev = (nodes: any) => {
    return nodes?.map((a: any) => {
      a["text"] = a["name"];
      delete a["name"];
      a["key"] = a["id"];
      delete a["id"];
      if (a.properties) {
        a.properties = JSON.stringify(a.properties);
      }
      a.color = "rgb(127,133,245)";
      a.highlighted = false;
      a.strokeWidth = 1;

      return a;
    });
  };
  
  const rootsMappingDev = (nodes: any, findText: string) => {
    return nodes?.map((a: any) => {
      a["text"] = a["name"];
      delete a["name"];
      a["key"] = a["id"];
      delete a["id"];
      if (a.properties) {
        a.properties = JSON.stringify(a.properties);
      }
      a.color = "rgb(127,133,245)";
      a.highlighted = true;
      a.strokeWidth = a.text.toLowerCase() === findText.toLowerCase() ? 5 : 3;

      return a;
    });
  };
  
  //const linksMapping = (links: IAllLinks[]) => {
  const linksMappingDev = (links: any) => {
    return links?.map((a: any) => {
      a["from"] = a["source_node_id"];
      delete a["source_node_id"];
      a["to"] = a["target_node_id"];
      delete a["target_node_id"];
      a["key"] = a["id"];
      delete a["id"];
      a["text"] = a["name"];
      delete a["name"];
      
      return a;
    })
  };
export { nodesMapping, rootsMapping, linksMapping, nodesMappingDev, rootsMappingDev, linksMappingDev };
