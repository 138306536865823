import { IDEMPOTENT_POST_PROCESSINGS, TASKMATE_DEFAULT_NAME, tabs } from "utils/app.constants";
import configuration from "configuration";
import { v4 as uuidv4 } from "uuid";
import {
  linksMapping,
  nodesMapping,
  rootsMapping,
} from "utils/GraphDataMapping";
import {
  IkeyStringValueAny,
  IGraphActions,
  IkeyStringValueString,
  INodePropertiesObject,
} from "./interfaces/interfaces";
import { USER_ID } from "utils/app.constants";
import { requestBuilderTs } from "utils/requestBuilder";

export const useGraphActions: (arg0: IGraphActions) => any = ({
  setToastError,
  setAllNodesAndLinks,
  directLine,
  selectedTabId,
  currentUser,
  allNodesAndLinks,
  setRestartDirectLine
}) => {

  const getGraphType = (tabId: number) => {
    return tabs[tabId - 1].label.toLocaleString().toLowerCase();
  };

  const handlerDirectLineResponse = (res: any) => {
    // check if the message is from server and is valid    
    let commands = res.channelData?.Results?.Results;
    
    if (commands) {
      localStorage.setItem(sessionStorage.getItem("tab") as string, JSON.stringify(commands));
      res.text = commands[0].MessageResponse;
      const postProcessingCommands = new Set<any>();
      commands.forEach((command: any) => {
        if (IDEMPOTENT_POST_PROCESSINGS.includes(command.PostProcess)) {
          postProcessingCommands.add(command);
        } else {
          executePostProcess(command);
        }
      });

      postProcessingCommands.forEach((postProcessingCommand: any) => {        
        executePostProcess(postProcessingCommand);
      });
    }
  };

  const executePostProcess = (
    command: {
      PostProcess: string;
      Command: string;
      Result?: any;
    },
    authToken?: string
  ) => {
    const postProcessCommand = command?.PostProcess;
    const result = command?.Result;
    
    switch (postProcessCommand) {
      case "reloadGraphFromResult": {
        const nodes = nodesMapping(result?.Nodes);
        const links = linksMapping(result?.Links);
        const roots = rootsMapping(
          result?.Roots,
          command.Command?.split('"')[1]
        );
        const message = "Reloading graph with new data received from result.";
        handlerSendMessageToAuditLog(message);

        if (nodes && links) {
          setAllNodesAndLinks({
            nodes: [...nodes, ...(roots || [])],
            links,
          });
        }        

        break;
      }

      case "reloadGraph": {
        const returnTabSessionStr = sessionStorage.getItem("tab") as string;
        loadGraph(getGraphType(JSON.parse(returnTabSessionStr)));

        break;
      }

      case "showResultInChat": {
        const message = "Show result in chat.";
        handlerSendMessageToAuditLog(message);

        break;
      }

      default: {
        const message = "Response is not valid.";
        handlerSendMessageToAuditLog(message);

        break;
      }
    }
  };

  const loadGraph = (graphType: string) => {
    if (directLine) {
      directLine
        .postActivity({
          type: "event",
          value: "get graph",
          channelData: {
            context: {
              graph_name: graphType,
            },
            apptype: "trainer",
            from: localStorage.getItem("userEmail"),
            userId:
              localStorage.getItem("userId") !== "undefined"
                ? localStorage.getItem("userId")
                : USER_ID,
            correlationId: uuidv4(),
            fullName: localStorage.getItem("fullName") !== "undefined"
              ? localStorage.getItem("fullName")
              : TASKMATE_DEFAULT_NAME
          },
          from: {
            id: directLine.userIdOnStartConversation,
            name: localStorage.getItem("userEmail"),
          },
          name: "getGraph",
        })
        .subscribe(
          () => { },
          (error: any) => {
            if (error.status === 404) {
              const authToken = localStorage.getItem("authToken");
              requestBuilderTs(
                `${configuration.chatApiURL}/${localStorage.getItem(
                  "userEmail"
                )}`,
                "DELETE",
                {
                  [`Authorization`]: `Bearer ${authToken}`,
                }
              ).then(() => {
                if (setRestartDirectLine) {
                  setRestartDirectLine(true);
                }
                window.location.href = window.location.href;
              });
            } else {
              setToastError(
                `Oops, something went wrong. We can't load the graph. Error message: ${error.message}`
              );
            }
          }
        );
    }
  };

  const defaultDirectLineHandler = (
    text: string,
    name: string,
    err: string
  ) => {
    if (directLine) {
      directLine
        .postActivity({
          type: "message",
          channelData: {
            context: {
              graph_name: getGraphType(selectedTabId),
            },
            apptype: "trainer",
            from: localStorage.getItem("userEmail"),
            userId:
              localStorage.getItem("userId") !== "undefined"
                ? localStorage.getItem("userId")
                : USER_ID,
            correlationId: uuidv4(),
            fullName: localStorage.getItem("fullName") !== "undefined"
              ? localStorage.getItem("fullName")
              : TASKMATE_DEFAULT_NAME
          },
          from: {
            id: directLine.userIdOnStartConversation,
            name: configuration.chatUserEmail,
          },
          name: name,
          text: text,
        })
        .subscribe(
          () => { },
          (error: any) =>
            setToastError(`Oops, we ${err}. Error details: ${error.message}`)
        );
    }
  };

  const handleAddNode = (name: string) => {
    defaultDirectLineHandler(`add ${name}`, "addNode", "cannot add this node");
    const message = `Adding node with name ${name}.`;

    handlerSendMessageToAuditLog(message);
  };

  const handleAddLink = (
    linkName: string,
    nodeNameFrom: string,
    nodeNameTo: string
  ) => {
    defaultDirectLineHandler(
      `add link ${linkName} between ${nodeNameFrom} and ${nodeNameTo}`,
      "addLink",
      "cannot add this link"
    );
    const message = `Adding link with name ${linkName} between ${nodeNameFrom} and ${nodeNameTo}.`;

    handlerSendMessageToAuditLog(message);
  };

  const handleDeleteLink = (
    linkName: string,
    nodeNameFrom: string,
    nodeNameTo: string
  ) => {
    defaultDirectLineHandler(
      `delete link ${linkName} between ${nodeNameFrom} and ${nodeNameTo}`,
      "deleteLink",
      "cannot delete this link"
    );
    const message = `Deleting link with name ${linkName} between ${nodeNameFrom} and ${nodeNameTo}.`;

    handlerSendMessageToAuditLog(message);
  };

  const handleDeleteNode = (name: string) => {
    defaultDirectLineHandler(
      `delete node ${name}`,
      "deleteNode",
      "cannot delete this node"
    );
    const message = `Deleting node with name ${name}.`;

    handlerSendMessageToAuditLog(message);
  };

  const handleUpdateNode = async (
    node: IkeyStringValueAny,
    addedProperties: INodePropertiesObject
  ) => {
    const addedKeys: string[] = Object.keys(addedProperties);

    await fetch(`${configuration.kbUpdateUri}/node/update`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        accept: "*/*",
      }),
      body: JSON.stringify({
        id: node.key,
        name: node.text,
        properties: {
          ...addedProperties,
        },
        graph_name: getGraphType(selectedTabId),
      }),
    })
      .then((response) => response.json())
      .then((data) =>
        setAllNodesAndLinks({
          ...allNodesAndLinks,
          nodes: allNodesAndLinks.nodes.map((node: any) => {
            //the object that updates the state with the added properties
            const propertiesToAdd: IkeyStringValueString = {};
            addedKeys.forEach(
              (key) => (propertiesToAdd[key] = data.properties[key])
            );

            return node.key === data.id
              ? { ...node, text: data.name, ...propertiesToAdd, properties: JSON.stringify(data.properties) }
              : node;
          }),
        })
      )
      .catch((error: any) =>
        setToastError(
          `Oops, we can't update the node. Error details: ${error.message}`
        )
      );
    const message = `Updating node with name ${node.text}.`;

    handlerSendMessageToAuditLog(message);
  };

  const handleUpdateLink = async (link: IkeyStringValueAny) => {
    await fetch(`${configuration.kbUpdateUri}/links/update`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        accept: "*/*",
      }),
      body: JSON.stringify({
        id: link.key,
        name: link.text,
        graph_name: getGraphType(selectedTabId),
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setAllNodesAndLinks({
          ...allNodesAndLinks,
          links: allNodesAndLinks.links.map((link: any) => {
            return link.key === data.id ? { ...link, text: data.name } : link;
          }),
        });
        loadGraph(getGraphType(selectedTabId));
      })
      .catch((error: any) =>
        setToastError(
          `Oops, we can't update the link. Error details: ${error.message}`
        )
      );
    const message = `Updating link with name ${link.text}.`;

    handlerSendMessageToAuditLog(message);
  };

  const handlerSendMessageToAuditLog = async (message: string) => {
    await fetch(configuration.loggerUrl, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        accept: "*/*",
      }),
      body: JSON.stringify({
        user: currentUser.email,
        message: message,
        application: "TeamsApp",
        correlationId: currentUser.userid
      }),
    })
      .catch((error: any) =>
        setToastError(
          `Oops, we can't send audit logs. Error details: ${error.message}`
        )
      );
  };

  const handleGetNodeChildren = (nodeName: string) => {
    defaultDirectLineHandler(`find ${nodeName} with depth 2`, "getNodeChildren", "cannot find node children");
    const message = `Finding ${nodeName} node's children`;

    handlerSendMessageToAuditLog(message);
  };

  return {
    handlerSendMessageToAuditLog,
    handlerDirectLineResponse,
    loadGraph,
    getGraphType,
    handleAddNode,
    handleAddLink,
    handleUpdateLink,
    handleDeleteLink,
    handleDeleteNode,
    handleUpdateNode,
    handleGetNodeChildren
  };
};
