import { useState, useRef, useEffect } from "react";
import go, { Diagram, ObjectData, Rect } from "gojs";
import { ReactDiagram } from "gojs-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import useGraph from "hooks/useGraph";
import DeleteNodeDialog from "./DeleteNodeDialog";
import { IAllLinks, IGraph } from "hooks/interfaces/interfaces";
import { checkNodeUpates, getUpdatedNodeProperties } from "service/UpdateNodeHelpers";

import styles from "styles/Graph/Graph.module.scss";
import "styles/global.css";

export const Graph = ({
  allNodes,
  allLinks,
  handleAddNode,
  handleDeleteNode,
  handleUpdateNode,
  handleAddLink,
  handleDeleteLink,
  handleUpdateLink,
  handleGetNodeChildren
}: IGraph) => {
  const { initDiagram, isInspector } = useGraph();
  const diagramRef = useRef<ReactDiagram>(null);
  const [diagram, setDiagram] = useState<Diagram | null>(null);
  const [deleteDialogNode, setDeleteDialogNode] = useState(null);
  const [newNodePropertyName, setNewNodePropertyName] = useState<string>("");
  const [clickedNodeName, setClickedNodeName] = useState<string>("");
  const { inspectorContainer, inspector, newPropertyForm, newPropertyForm_input, newPropertyForm_button } = styles;

  // adds a new input for adding a new property to a node and updates the gojs inspector model
  const addNodeProperty = (property: string) => {
    const selection = diagram?.selection.toArray().at(0)?.data;
    const allDisplayedNodes = diagram?.model.nodeDataArray;
    const selectedNode = allDisplayedNodes?.find(node => node.key === selection.key);

    if (selectedNode && property.length > 1) {
      Object.assign(selectedNode, { [property]: "" });
      diagram?.model.commit(m => m.set(selectedNode, property, ""));
    }

    setNewNodePropertyName("");
  };

  useEffect(() => {
    if (diagramRef.current) {
      const diagram = diagramRef.current.getDiagram();

      if (diagram) {
        diagram.commandHandler.doKeyDown = () => {
          if (
            diagram.lastInput.key === "Del" &&
            diagram.selection.count !== 0
          ) {
            const selection = diagram.selection.toArray().at(0)?.data;

            if (selection.properties) {
              // selection is a node
              setDeleteDialogNode(selection);
              return;
            }
          }
          go.CommandHandler.prototype.doKeyDown.call(diagram.commandHandler);
        };
        setDiagram(diagram);
      }
    }
  }, [diagramRef]);

  const model = diagram?.model;
  if (model instanceof go.GraphLinksModel) {
    model.startTransaction("clearLinks");
    model.linkDataArray = [];
    model.commitTransaction("clearLinks");
    model.linkDataArray = allLinks;
    // diagram?.centerRect(new Rect(0,0,0,0));
    // diagram!.contentAlignment = go.Spot.Center;
  }

  function searchNode(nameKey: string, myArray: any) {
    for (let i = 0; i < myArray.length; i++) {
      if (myArray[i].key === nameKey) {
        return myArray[i].text;
      }
    }
  }

  const handleModelChange = (e: go.IncrementalData) => {
    if (e.removedLinkKeys && e.removedNodeKeys) {
      const deletedLink: any = allLinks.find(
        (link: any) => link.key === e.removedLinkKeys?.[0]
      );
      if (deletedLink) {
        handleDeleteLink(
          deletedLink.text,
          searchNode(deletedLink.from, allNodes),
          searchNode(deletedLink.to, allNodes)
        );
      }

      const deletedNode = allNodes.find(
        (node: any) => node.key === e.removedNodeKeys?.at(0)
      );
      if (deletedNode) {
        handleDeleteNode(deletedNode.text);
      }
      return;
    }

    if (e.insertedNodeKeys || e.modifiedNodeData!.length < 25) {
      diagram?.centerRect(new Rect(0, 0, 0, 0));
      diagram!.contentAlignment = go.Spot.TopLeft;
      diagram!.scale = 0.6;
    }

    // to watch
    if (e.removedLinkKeys) {
      const deletedLink: any = allLinks.find(
        (link: any) => link.key === e.removedLinkKeys?.[0]
      );

      if (deletedLink) {
        handleDeleteLink(
          deletedLink.text,
          searchNode(deletedLink.from, allNodes),
          searchNode(deletedLink.to, allNodes)
        );
        return;
      }
    }

    // Delete a node
    if (e.removedNodeKeys) {
      const deletedNode = allNodes.find(
        (node: any) => node.key === e.removedNodeKeys?.at(0)
      );
      if (deletedNode) {
        handleDeleteNode(deletedNode.text);
        return;
      }
    }

    // Rename a node: a new added node or a old node
    if (e.modifiedNodeData && !e.insertedNodeKeys) {
      // skip the 'new node'
      if (
        e.modifiedNodeData.length === 1 &&
        e.modifiedNodeData[0].text === "new node"
      )
        return;
      // the modified node is a new node
      const addedNode = e.modifiedNodeData.find(
        (node: any) => !node.properties && node.text !== "new node"
      );
      if (addedNode?.text) {
        handleAddNode(addedNode.text);

        return;
      }

      // the modified node is an old node
      if (e.modifiedNodeData[0] && e.modifiedNodeData.length === 1) {
        const newNode = e.modifiedNodeData[0];

        const node = allNodes.find(
          (node: ObjectData) =>
            !!e.modifiedNodeData && node.key === e.modifiedNodeData[0].key
        );

        if (node) {
          const addedProperties = getUpdatedNodeProperties(node, newNode);
          const areEqual = checkNodeUpates(node, newNode);

          if (!areEqual && newNode.text) {
            handleUpdateNode({ ...node, ...newNode }, addedProperties);
            return;
          }
        }
      }
    }

    // Update Link
    const existingKeys = allLinks.map((link) => link.key);
    if (
      e.modifiedLinkData &&
      !!e.modifiedLinkData[0].text &&
      existingKeys.includes(e.modifiedLinkData[0].key) &&
      e.modifiedLinkData.length === 1
    ) {
      const existingLink = allLinks.find((link) => {
        if (e.modifiedLinkData) return link.key === e.modifiedLinkData[0].key;
      });
      if (existingLink) {
        const existingLinkKeys = Object.keys(existingLink);
        let areEqual = true;
        const modifiedObject = e.modifiedLinkData[0];
        existingLinkKeys.forEach((key) => {
          if (existingLink[key as keyof IAllLinks] !== modifiedObject[key]) {
            areEqual = false;
          }
        });

        if (!areEqual && modifiedObject.text) {
          handleUpdateLink({ ...existingLink, ...modifiedObject });
          return;
        }

        if (!areEqual) {
          handleDeleteLink(
            existingLink.text,
            searchNode(existingLink.from, allNodes),
            searchNode(existingLink.to, allNodes)
          );
          handleAddLink(
            e.modifiedLinkData[0].text,
            searchNode(e.modifiedLinkData[0].from, allNodes),
            searchNode(e.modifiedLinkData[0].to, allNodes)
          );
          return;
        }
      }
    } else if (e.modifiedLinkData && !e.insertedLinkKeys) {
      if (e.modifiedLinkData[0] && e.modifiedLinkData[0].text) {
        handleAddLink(
          e.modifiedLinkData[0].text,
          searchNode(e.modifiedLinkData[0].from, allNodes),
          searchNode(e.modifiedLinkData[0].to, allNodes)
        );
      }
    }
  };

  // add a listener to the diagram to get the clicked node name and call the handleGetNodeChildren function
  diagram?.addDiagramListener("ObjectSingleClicked", () => setClickedNodeName(diagram?.selection.toArray().at(0)?.data.text));
  useEffect(() => {
    if (clickedNodeName) {
      handleGetNodeChildren(clickedNodeName);
    }
  }, [clickedNodeName])

  return (
    <div className="w-full h-full flex flex-row">
      <ReactDiagram
        ref={diagramRef}
        divClassName="diagram-component"
        initDiagram={initDiagram}
        skipsDiagramUpdate={false}
        nodeDataArray={allNodes}
        linkDataArray={allLinks}
        onModelChange={handleModelChange}
      />
      <div className={inspectorContainer}>
        <div
          id="myInspectorDiv"
          className={`basis-1/5 hidden ${inspector}`}
        />
        {isInspector &&
          <div className={newPropertyForm}>
            <input
              className={newPropertyForm_input}
              type="text"
              value={newNodePropertyName}
              placeholder="Add new property"
              onChange={(e) => setNewNodePropertyName(e.target.value)}
            />
            <button
              className={newPropertyForm_button}
              onClick={() => addNodeProperty(newNodePropertyName)}
            >
              <FontAwesomeIcon icon={faPlus} size="lg" />
            </button>
          </div>
        }
      </div>
      <DeleteNodeDialog
        node={deleteDialogNode}
        onClose={() => setDeleteDialogNode(null)}
        onDelete={() => { diagram?.commandHandler.deleteSelection(); diagram?.commandHandler.resetZoom(); }}
      />
    </div>
  );
};

