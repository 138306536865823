import configuration from "configuration";
import { fetchSpeechServicesCredentials } from "utils/fetchSpeechServicesCredentials";
import { requestBuilderTs } from "utils/requestBuilder";

const WebChat = window["WebChat" as keyof typeof window];

 export const createDirectLineFunction = (
   conversationDataCallUrl: string,
   authToken: string,
   setDirectLine: any,
   setPonyfill: any
 ) => {
   requestBuilderTs(conversationDataCallUrl, "GET", {
     [`Authorization`]: `Bearer ${authToken}`,
   }).then(async (conversationDataReponse) => {
     let directLineStandardCfg: { [key: string]: string } = {
       token: configuration.token,
     };

     if (!!conversationDataReponse && conversationDataReponse?.value) {
       directLineStandardCfg = {
         ...directLineStandardCfg,
         conversationId: conversationDataReponse.value.conversationId,
         streamUrl: conversationDataReponse.value.streamUrl,
         watermark: conversationDataReponse.value.watermark,
       };
     }
 
     const directLine = WebChat.createDirectLine({
       ...directLineStandardCfg,
       domain: "https://europe.directline.botframework.com/v3/directline"
     });
     
    const speechServiceToken = await fetchSpeechServicesCredentials();
    const ponyfill = WebChat.createCognitiveServicesSpeechServicesPonyfillFactory({
      credentials: {authorizationToken: `Bearer ${speechServiceToken}`, region: 'westeurope'},
    });
     setPonyfill(ponyfill);
     setDirectLine(directLine);
   });
 };
 